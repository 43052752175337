import styled from 'styled-components'
import { media } from '../../constants/media'

export const MainTitle = styled.h1`
  font-family: ${props => props.theme.fonts.solway};
  color: ${props => props.theme.colors.nori};
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.2rem;
  letter-spacing: 0.048rem;
  @media ${media.medium.query} {
    font-size: 6rem;
    line-height: 6.1rem;
    letter-spacing: 0.07rem;
  }
`;

export const MainSubtitle = styled.h2`
  font-family: ${props => props.theme.fonts.solway};
  color: ${props => props.theme.colors.nori};
  text-align: center;
  font-weight: 800;
  font-size: 3.6rem;
  line-height: 4.2rem;
  letter-spacing: 0.03rem;
  @media ${media.medium.query} {
    text-align: left;
    font-size: 5.5rem;
    line-height: 6rem;
    letter-spacing: 0.064rem;
  }
`;

export const BlogSubtitle = styled.h3`
  font-family: ${props => props.theme.fonts.solway};
  font-weight: 800;
  font-size: 2.8rem;
  line-height: 3.2rem;
  letter-spacing: 0.02rem;
  color: ${props => props.theme.colors.nori};
  @media ${media.medium.query} {
    font-size: 3.5rem;
    line-height: 6.1rem;
    letter-spacing: 0.041rem;
  }
`;

export const CountryTitle = styled.h5`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.salmon};
  font-weight: bold;
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  @media ${media.medium.query} {
    font-size: 2.3rem;
    letter-spacing: 0.053rem;
  }
`;

export const BodyText = styled.p`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.nori};
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 3rem;
  @media ${media.medium.query} {
    font-weight: normal;
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
`;

export const Link = styled.a`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.pacific};
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.3rem;
  text-decoration: none;
  :visited {
    color: ${props => props.theme.colors.pacific};
  }
  :hover {
    text-decoration: underline;
  }
`;