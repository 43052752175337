import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { MapComponent } from './map-component'

const Map = props => (
  <StaticQuery
    query={googleMapsApiKeyQuery}
    render={data => (
      <MapComponent
        googleMapsApiKey={data.site.siteMetadata.googleMapsApiKey}
        {...props}
      />
    )}
  />
)

const googleMapsApiKeyQuery = graphql`
  query googleMapsApiKeyQuery {
    site {
      siteMetadata {
        googleMapsApiKey
      }
    }
  }
`;

export { Map }