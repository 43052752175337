import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'

import { getCountryNameByIsoCode } from '../../../helpers/country/country'
import { theme } from '../../../constants/theme'
import * as S from './map-styled'

const MapComponent = ({ googleMapsApiKey, highlightedCountries, handleSelectCountry }) => {
  const data = [ ['Countries'] ];

  const chartEvents = [
    {
      eventName: 'select',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper.getDataTable();
          const { row } = selectedItem;
          const column = 0;
          const country = dataTable.getValue(row, column);
          handleSelectCountry(country);
        }
      }
    }
  ]

  for (const country of highlightedCountries) {
    const formattedData = { v: country, f: getCountryNameByIsoCode(country) };
    data.push([formattedData]);
  }

  return (
    <S.ChartWrapper>
      <Chart
        chartType="GeoChart"
        width="100%"
        height="100%"
        data={data}
        options={{
          backgroundColor: 'transparent',
          datalessRegionColor: 'transparent',
          defaultColor: theme.colors.salmon,
        }}
        mapsApiKey={googleMapsApiKey}
        chartEvents={handleSelectCountry ? chartEvents : null}
      />
    </S.ChartWrapper>
  )
}

MapComponent.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  highlightedCountries: PropTypes.array,
  handleSelectCountry: PropTypes.func,
}

export { MapComponent }